import type { ProfileTypes, ProfileV2 } from 'src/types';

export const getProfileTrackingFields = (
  profile: ProfileV2,
): {
  profileVerifications: string[];
  profileNationalityCount: number;
  profileType: ProfileTypes;
  profileId: string;
} => {
  const { rightToWork, licences, profileEducation } = profile;
  const profileVerifications = [];

  if ((licences?.filter((licence) => licence.isVerified).length ?? 0) > 0) {
    profileVerifications.push('licence');
  }

  if (rightToWork?.isVerified) {
    profileVerifications.push('rtw');
  }

  const verifiedEducationCount =
    profileEducation?.filter((education) => education.isVerified).length ?? 0;
  if (verifiedEducationCount > 0) {
    profileVerifications.push('education');
  }

  // @TODO: Replace this with the correct lookup of the Profile key once it's available
  if (false) {
    profileVerifications.push('id');
  }

  const profileNationalityCount = profile.nationalities.length;
  const profileType = profile.profileType;
  const profileId = String(profile.profileId);

  return {
    profileVerifications,
    profileNationalityCount,
    profileType,
    profileId,
    // Please add new tealium tracking fields here
  };
};
