import { getConnectionRate } from '@seek/talentsearch-credit-rates';
import { HirerConnection } from '@seek/talentsearch-entity-types';

import { ACL, type CreditRates } from '../../types';

export const getCreditRates = (productType: ACL.ProductType): CreditRates => {
  switch (productType) {
    case ACL.PRODUCT_TYPE.CTS:
      return {
        sendJob: getConnectionRate(HirerConnection.ConnectionType.ITA),
        sendMessage: getConnectionRate(HirerConnection.ConnectionType.ATS_MSG),
        accessCv: 0,
        accessProfile: 0,
        accessProfileAndCv: 0,
        exportSeekProfile: 0,
        exportSharedProfile: 0,
        refreshSharedProfile: 0,
        exportProfileCts: getConnectionRate(
          HirerConnection.ConnectionType.PROFILE_EXPORT_ATS,
        ),
      };
    // PTS is the default
    default:
      return {
        sendJob: getConnectionRate(HirerConnection.ConnectionType.ITA),
        sendMessage: getConnectionRate(HirerConnection.ConnectionType.MSG),
        accessCv: getConnectionRate(HirerConnection.ConnectionType.JSP),
        accessProfile: getConnectionRate(
          HirerConnection.ConnectionType.ACCESS_PROFILE,
        ),
        accessProfileAndCv: getConnectionRate(
          HirerConnection.ConnectionType.ACCESS_PROFILE_AND_CV,
        ),
        exportSeekProfile: 0,
        exportSharedProfile: 0,
        refreshSharedProfile: 0,
        exportProfileCts: 0,
      };
  }
};
