import { ConnectionTypes } from 'src/types';

export const getAccessCvType = (
  connectionType: ConnectionTypes,
): string | undefined => {
  if (connectionType === ConnectionTypes.AccessProfile) {
    return 'access profile';
  }

  if (connectionType === ConnectionTypes.AccessProfileAndCv) {
    return 'access cv profile';
  }

  return undefined;
};
