import { useTranslations } from '@vocab/react';
import { Stack, Box, Text } from 'braid-design-system';
import { useSelector } from 'react-redux';

import { useConstantUrls } from 'src/hooks/useConstUrls/useConstantUrls';
import { useRoutes } from 'src/hooks/useRoutes';

import { useACL } from '../../../../../../../hooks/useACL/useACL';
import useAppConfig from '../../../../../../../hooks/useAppConfig';
import { selectors as balanceSelectors } from '../../../../../../../store/balance';
import { selectors as routerSelectors } from '../../../../../../../store/router';
import { selectors as userSelectors } from '../../../../../../../store/user';
import translations from '../../PageLinks/.vocab';
import MobileMenuLink from '../MobileMenuLink/MobileMenuLink';

import { getPageLinks } from './../../PageLinks/getPageLinks';

export type LinkType = 'common' | 'credit';

interface Props {
  onSelect: () => void;
  linkType: LinkType;
}

const PageLinks = ({ onSelect, linkType }: Props) => {
  const { getProductConfig, getProductType } = useACL();
  const { routes } = useRoutes();
  const { COUNTRY_CODE: countryCode, IS_LEGACY_SITE: isLegacySite } =
    useAppConfig();

  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);
  const creditBalance = useSelector(balanceSelectors.getCreditBalanceSelector);
  const jobId = useSelector(routerSelectors.getActiveJobIdSelector);
  const { t } = useTranslations(translations);

  const { links } = getProductConfig();

  const pageLinkItems = links.filter((sceneLink) => sceneLink !== 'credits');

  const creditLinkItems = links.filter((sceneLink) => sceneLink === 'credits');
  const { creditDashboardUrl } = useConstantUrls();
  const linkItems = getPageLinks(
    linkType === 'credit' ? creditLinkItems : pageLinkItems,
    {
      creditDashboardUrl,
      advertiserId,
      jobId,
      creditBalance,
      countryCode,
      productType: getProductType(),
      isLegacySite,
      t,
    },
    routes,
  );

  return (
    <Stack space="none">
      {linkItems.map(
        (
          { href, hasCreditsMetricsEvent, isExternal, isActive, children },
          index,
        ) => (
          <MobileMenuLink
            key={index}
            href={href}
            hasCreditsMetricsEvent={hasCreditsMetricsEvent}
            isExternal={isExternal}
            isActive={isActive}
            onClick={onSelect}
            linkType={linkType}
          >
            <Box>
              <Text>{children}</Text>
            </Box>
          </MobileMenuLink>
        ),
      )}
    </Stack>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default PageLinks;
