import { useContext } from 'react';

import Context from './Context';
import type { ContextType } from './types';

function useACL() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useACL must be used within ACLProvider');
  }

  return context as ContextType;
}

export { useACL };
