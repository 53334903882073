import type { Locale } from '@seek/melways-sites';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useAppConfig from 'src/hooks/useAppConfig';

import { useACL } from '../../../../hooks/useACL/useACL';
import useDataService from '../../../../hooks/useDataService';
import * as metadataServices from '../../../../services/data/metadata';
import { actionCreators as metadataActionCreators } from '../../../../store/metadata';
import { ACL } from '../../../../types';

function MetadataContainer() {
  const dispatch = useDispatch();
  const { hasAccess } = useACL();
  const { LOCALE: locale } = useAppConfig();

  const { getIndustries, getUncoupledQueryValidations } = useDataService({
    getIndustries: metadataServices.getIndustries,
    getUncoupledQueryValidations: metadataServices.getUncoupledQueryValidations,
  });

  useEffect(() => {
    (async function fetchMetadata() {
      try {
        const industries = await getIndustries({ locale: locale as Locale });
        dispatch(metadataActionCreators.updateIndustries(industries));

        if (hasAccess(ACL.SCENE_FEATURE.SEARCH_UNCOUPLED)) {
          const uncoupledQueryValidations =
            await getUncoupledQueryValidations();
          dispatch(
            metadataActionCreators.updateUncoupledQueryValidations(
              uncoupledQueryValidations,
            ),
          );
        }
      } catch (err) {
        //
      }
    })();
  }, [
    dispatch,
    getIndustries,
    getUncoupledQueryValidations,
    hasAccess,
    locale,
  ]);

  return null;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MetadataContainer;
