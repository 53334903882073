import {
  type ApolloQueryResult,
  type FetchResult,
  type OperationVariables,
  useApolloClient,
  type WatchQueryOptions,
} from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import errorService from '../../services/error';
import messageService from '../../services/message';
import { selectors as userSelectors } from '../../store/user';
import {
  AccessMethods,
  type AxiosOrGraphQLError,
  type Services,
  ErrorTypes,
} from '../../types';
import type { PollQueryOptions } from '../../types/services';
import { useACL } from '../useACL/useACL';
import useAuth from '../useAuth/useAuth';
import useNotifications from '../useNotifications/useNotifications';
import useRouter from '../useRouter/useRouter';
import { useRoutes } from '../useRoutes';

import Context from './Context';

interface Props {
  children: React.ReactNode;
}

const MAX_POLL_RETRIES = 10;
const MAX_POLL_TIMEOUT = 2000;

function DataServiceProvider({ children }: Props) {
  const { loginWithCallback } = useAuth();
  const apolloClient = useApolloClient();
  const { navigateToError } = useRouter();
  const { getProductConfig } = useACL();
  const { showBanner } = useNotifications();
  const { routes } = useRoutes();

  const lastAccessMethod = useSelector(
    userSelectors.getLastAccessMethodSelector,
  );
  const navigateToErrorRef = useRef<typeof navigateToError>(navigateToError);

  const handleErrorRef = useRef<
    (error: AxiosOrGraphQLError) => PromiseLike<typeof error> | void
  >((err: AxiosOrGraphQLError) => {
    //  Handles any forbidden errors
    if (errorService.isUnrecoverableError(err)) {
      return navigateToErrorRef.current(routes, ErrorTypes.UNAUTHORISED, {
        err,
      });
    }

    //  Handles unauthorised errors (401s are retried once)
    if (errorService.isUnauthorisedError(err)) {
      return lastAccessMethod === AccessMethods.Direct
        ? loginWithCallback()
        : navigateToErrorRef.current(routes, ErrorTypes.UNAUTHORISED, { err });
    }

    //  Finds a matching banner for the error and shows it if one matches
    //  error banners ensure our UI is still in a recoverable state
    const matchingMessageForError = messageService.createMessageFromError(err);
    if (matchingMessageForError) {
      showBanner(matchingMessageForError);
    }

    return Promise.reject(err);
  });

  const context = useMemo<Services.DataServiceContext>(
    () => ({
      query: async (options) => {
        try {
          const result = (await apolloClient.query(
            options,
          )) as ApolloQueryResult<any>;
          return result?.data || null;
        } catch (err) {
          return handleErrorRef.current(err as ErrorResponse);
        }
      },
      pollQuery: async <T, TVariables extends OperationVariables>(
        options: WatchQueryOptions<TVariables, T>,
        pollQueryOptions?: PollQueryOptions<T>,
      ) => {
        const { maxRetries = MAX_POLL_RETRIES, stopPollingWhen } =
          pollQueryOptions || {};
        const pollInterval = options.pollInterval || MAX_POLL_TIMEOUT;
        const timeout =
          pollQueryOptions?.timeout || (maxRetries + 1) * pollInterval;
        const observable = apolloClient.watchQuery<T, TVariables>({
          ...options,
          pollInterval,
        });

        let _timeout;
        try {
          const result = await new Promise<ApolloQueryResult<any>>(
            (resolve, reject) => {
              let retryAttempts = 0;
              _timeout = setTimeout(
                () => reject('pollQuery timed out'),
                timeout,
              );

              observable.subscribe((nextValue: ApolloQueryResult<T>) => {
                const error =
                  nextValue?.errors && nextValue?.errors?.length >= 1
                    ? nextValue.errors[0]
                    : nextValue?.error;

                if (error) {
                  reject(error);
                } else if (
                  retryAttempts >= maxRetries ||
                  (stopPollingWhen && stopPollingWhen(nextValue))
                ) {
                  resolve(nextValue);
                }

                retryAttempts += 1;
              });
            },
          );

          return result?.data || null;
        } catch (err) {
          return handleErrorRef.current(err as ErrorResponse);
        } finally {
          observable.stopPolling();
          clearTimeout(_timeout);
        }
      },
      mutate: async (options) => {
        try {
          const result = (await apolloClient.mutate(
            options,
          )) as FetchResult<any>;
          return result?.data || null;
        } catch (err) {
          return handleErrorRef.current(err as ErrorResponse);
        }
      },
    }),
    [apolloClient, getProductConfig],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DataServiceProvider;
