import { getSeekId, setSeekId } from '@seek/adv-account-selection';

import {
  type ATSJobsInput,
  type HirerDetails,
  Nations,
  type RootState,
  Search,
} from '../../types';
import { getActiveJobCountryCodeSelector } from '../job/selectors';
import { getParamFromSearchSelector } from '../router/selectors';

import { updatePreferredAdvertiserIdLegacy } from './actionCreators';
import { type State, overrideSettings } from './reducer';

export const getSearchExperimentSelector = ({
  settings,
}: RootState): string | null => {
  const bucketNameOverride = settings.searchExperimentOverride;
  if (bucketNameOverride) {
    return bucketNameOverride;
  }
  const bucketName = settings.searchExperiment;
  return bucketName && bucketName !== 'champion' ? bucketName : null;
};

export const getEnPseudoModeSelector = ({ settings }: RootState): boolean =>
  settings.enPseudoMode === 'true';

export const getEthicalHiringSelector = ({ settings }: RootState): boolean =>
  settings.ethicalHiring === 'true';

export const getVerifiedSignalsAlertDismissedSelector = ({
  settings,
}: RootState): boolean => settings.betaVerifiedSignalsAlertDismissed === 'true';

export const getVerifiedIdAlertDismissedSelector = ({
  settings,
}: RootState): boolean => settings.verifiedIdAlertDismissed === 'true';

export const getRecentSearchesSelectorByLocalStorageKey =
  (settingKey: keyof State) =>
  ({ settings }: RootState): Search.RecentSearch[] => {
    const value = settings[settingKey] as string;
    return !value ? [] : JSON.parse(value);
  };

export const getUserSessionIdSelector = ({
  settings,
}: RootState): string | null => settings.userSessionId;

export const getDefaultNationSelector = ({ settings }: RootState): Nations =>
  settings.nation || Nations.AU;

export const getActiveNationSelector = (state: RootState) => {
  //  Return the active nation from ?nation=3000
  const nationFromSearchParams: string | null = getParamFromSearchSelector(
    state,
    Search.CriteriaKey.Nation,
  ) as any;
  if (nationFromSearchParams) {
    return Number(nationFromSearchParams);
  }

  const salaryNationFromSearchParams: string | null =
    getParamFromSearchSelector(state, Search.CriteriaKey.SalaryNation) as any;
  if (salaryNationFromSearchParams) {
    return Number(salaryNationFromSearchParams);
  }

  //  Return the default nation from settings (fallback)
  return getDefaultNationSelector(state);
};

export const getActiveNationCountryCodeSelector = (
  state: RootState,
): string => {
  //  Return the country code from a selected job
  const countryCodeFromActiveJob = getActiveJobCountryCodeSelector(state);
  if (countryCodeFromActiveJob) {
    return countryCodeFromActiveJob;
  }

  return Nations[getActiveNationSelector(state)];
};

export const getSeenFeatureHighlights = ({ settings }: RootState): string[] => {
  const value = settings.featureHighlights;
  return !value ? [] : JSON.parse(value);
};

export const getAccessProfileEmailSelector = ({
  settings,
}: RootState): string => {
  const value = settings.accessProfileEmail;
  return value ? value.replace(/"/g, '') : '';
};

export const getSendMessageHirerDetailsSelector = ({
  settings,
}: RootState): HirerDetails | null => {
  const value = settings.sendMessageHirerDetails;
  return value ? JSON.parse(value) : null;
};

export const getSuppressSendJobModalSelector = ({ settings }: RootState) => {
  const value = settings.suppressSendJobModal;
  return value ? value === 'true' : false;
};

export const getSettingsOverrideSelector = ({
  settings,
}: RootState): Array<{ key: string; value: string }> => {
  const output = [];
  for (const keyInSettings of Object.values(overrideSettings)) {
    const settingsReferenceValue = settings[keyInSettings];
    if (settingsReferenceValue) {
      output.push({ key: keyInSettings, value: settingsReferenceValue });
    }
  }
  return output;
};

export const getBetaEnvironmentSelector = ({
  settings,
}: RootState): string | null => {
  const value = settings.betaEnvironment;
  return value || null;
};

export const getRecentATSJobSearchSelector = ({
  settings,
}: RootState): ATSJobsInput => {
  const value = settings.recentATSJobSearch;
  return !value ? null : JSON.parse(value);
};

export const getPreferredAdvertiserId = ({ settings }: RootState) => {
  //
  // If user already logged in, but Advertiser Id is stored in the old fashion,
  // transfer the Advertiser Id to new storage and invalidate the old one
  //
  if (
    Boolean(settings.preferredAdvertiserId) &&
    settings.preferredAdvertiserId !== 'null'
  ) {
    setSeekId(Number(settings.preferredAdvertiserId));
    updatePreferredAdvertiserIdLegacy(null);
  }

  const preferredAdvertiserId = Number(getSeekId());
  return !isNaN(preferredAdvertiserId) ? preferredAdvertiserId : null;
};

export const getShowMarketSelectorInfoDialogueSelector = ({
  settings,
}: RootState): boolean | undefined =>
  settings.showMarketSelectorInfoDialogue
    ? settings.showMarketSelectorInfoDialogue === 'true'
    : undefined;

export const getRecommendedCandidatesAlertState = ({ settings }: RootState) =>
  settings.recommendedCandidatesAlert;
