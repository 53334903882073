import { selectors as profilesSelectors } from '../../store/profiles';
import { selectors as routerSelectors } from '../../store/router';
import { selectors as searchesSelectors } from '../../store/searches';
import { selectors as settingsSelectors } from '../../store/settings';
import { selectors as userSelectors } from '../../store/user';
import {
  ConnectionTypes,
  type RootState,
  type ProfileV2,
  type HirerConnectionTypes,
} from '../../types';
import { DATAPLATFORM_KEY } from '../dataPlatform';
import dataPlatformSelectors from '../dataPlatform/selectors';
import {
  Source,
  TabLocations,
  EventName as DataPlatformEventName,
} from '../dataPlatform/types';
import getDefaultProfileFields from '../dataPlatform/utils/getDefaultProfileFields';
import { TEALIUM_KEY } from '../tealium';
import tealiumSelectors from '../tealium/selectors';
import { UtagActions, Profile as ProfileLinks } from '../tealium/types';
import { getAccessCvType } from '../tealium/utils/getAccessCvType';
import { getProfileTrackingFields } from '../tealium/utils/getProfileTrackingFields';
import getProfileTypes from '../tealium/utils/getProfileTypes';
import { decodeAtsJobExternalReferenceId } from '../utils/identifierDecoder';

const actions = {
  connectionFailed: ({
    profile,
    connectionType,
  }: {
    profile: ProfileV2;
    connectionType: ConnectionTypes;
  }) => [
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);

        const connectionId = null;

        const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
        const advertiserId = userSelectors.getAdvertiserIdSelector(snapshot);
        const userId = userSelectors.getUserIdSelector(snapshot);
        const organisationId = userSelectors.getCompanyIdSelector(snapshot);
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = profilesSelectors.getProfilePositionSelector(
          snapshot,
          profile,
        );
        const similarCandidatesSearchId =
          searchesSelectors.getSimilarCandidatesSearchIdSelector(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);

        return {
          value: {
            ...(connectionType === ConnectionTypes.SendJob && {
              eventName: DataPlatformEventName.TSSendJobFailed,
            }),
            ...(connectionType === ConnectionTypes.SendMessage && {
              eventName: DataPlatformEventName.TSSendMessageFailed,
            }),
            ...(connectionType === ConnectionTypes.AccessCV && {
              eventName: DataPlatformEventName.TSAccessCvFailed,
            }),
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              advertiserId,
              userId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  connectionInitiated: ({
    profile,
    connectionType,
  }: {
    profile: ProfileV2;
    connectionType: ConnectionTypes;
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const seedProfileId =
          routerSelectors.getSeedProfileIdSelector(snapshot);
        const profilePositionOnResults =
          profilesSelectors.getProfilePositionSelector(snapshot, profile, true);
        const availableConnectionTypes =
          tealiumSelectors.getConnectionTypesAvailableSelector(
            snapshot,
            profile,
          );
        const advertiserType =
          tealiumSelectors.getAdvertiserTypesSelector(snapshot);

        const profileTypes = getProfileTypes(profile);
        const accessCvType = getAccessCvType(connectionType);

        return {
          action: UtagActions.Link,
          value: {
            ...(connectionType === ConnectionTypes.SendJob && {
              eventName: ProfileLinks.SendJobClick,
            }),
            ...(connectionType === ConnectionTypes.SendMessage && {
              eventName: ProfileLinks.SendMessageClick,
            }),
            ...([
              ConnectionTypes.AccessCV,
              ConnectionTypes.AccessProfile,
              ConnectionTypes.AccessProfileAndCv,
            ].includes(connectionType) && {
              eventName: ProfileLinks.AccessCVClick,
            }),
            ...(connectionType === ConnectionTypes.ExportProfileCts && {
              eventName: ProfileLinks.ExportAtsProfileToJobClick,
            }),
            profileTypes,
            advertiserType,
            profilePositionOnResults: profilePositionOnResults ?? 0,
            availableConnectionTypes,
            accessCvType,
            seedProfileId,
            ...getProfileTrackingFields(profile),
          },
        };
      },
    },
  ],
  connectionSuccess: ({
    profile,
    connectionType,
    connectionId,
    externalReferenceId,
    atsId,
    atsName,
    jobId,
  }: {
    profile: ProfileV2;
    connectionType: HirerConnectionTypes;
    connectionId: string;
    atsId?: number;
    atsName?: string;
    externalReferenceId?: string;
    jobId?: string;
  }) => {
    const decodedExternalReferenceId =
      externalReferenceId &&
      decodeAtsJobExternalReferenceId(externalReferenceId);
    return [
      {
        key: TEALIUM_KEY,
        getValue: (snapshot: RootState) => {
          const seedProfileId =
            routerSelectors.getSeedProfileIdSelector(snapshot);
          const availableConnectionTypes =
            tealiumSelectors.getConnectionTypesAvailableSelector(
              snapshot,
              profile,
            );
          const advertiserType =
            tealiumSelectors.getAdvertiserTypesSelector(snapshot);

          const accessCvType = getAccessCvType(connectionType);

          const eventNameByConnectionType: Record<
            HirerConnectionTypes,
            ProfileLinks
          > = {
            [ConnectionTypes.SendJob]: ProfileLinks.SendJobCompleted,
            [ConnectionTypes.SendMessage]: ProfileLinks.SendMessageCompleted,
            [ConnectionTypes.AccessCV]: ProfileLinks.AccessCVCompleted,
            [ConnectionTypes.AccessProfile]: ProfileLinks.AccessCVCompleted,
            [ConnectionTypes.AccessProfileAndCv]:
              ProfileLinks.AccessCVCompleted,
            [ConnectionTypes.ExportProfileCts]:
              ProfileLinks.ExportAtsProfileToJobCompleted,
          };

          return {
            action: UtagActions.Link,
            value: {
              eventName: eventNameByConnectionType[connectionType],
              advertiserType,
              accessCvType,
              availableConnectionTypes,
              seedProfileId,
              ...getProfileTrackingFields(profile),
            },
          };
        },
      },
      {
        key: DATAPLATFORM_KEY,
        getValue: (snapshot: RootState) => {
          const defaultProfileFields = getDefaultProfileFields(profile);

          const advertiserId = userSelectors.getAdvertiserIdSelector(snapshot);
          const userId = userSelectors.getUserIdSelector(snapshot);
          const userSessionId =
            settingsSelectors.getUserSessionIdSelector(snapshot);
          const organisationId = userSelectors.getCompanyIdSelector(snapshot);
          const searchId = searchesSelectors.getSearchIdSelector(snapshot);
          const fromProfilePage =
            dataPlatformSelectors.getFromProfilePageSelector(snapshot);
          const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
          const profilePosition = profilesSelectors.getProfilePositionSelector(
            snapshot,
            profile,
          );
          const similarCandidatesSearchId =
            searchesSelectors.getSimilarCandidatesSearchIdSelector(snapshot);
          const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
          const productType = userSelectors.getProducTypeForMetrics(
            snapshot,
            true,
          );

          return {
            value: {
              ...(connectionType === ConnectionTypes.SendJob && {
                eventName: DataPlatformEventName.TSSendJobCompleted,
              }),
              ...(connectionType === ConnectionTypes.SendMessage && {
                eventName: DataPlatformEventName.TSSendMessageCompleted,
              }),
              ...(connectionType === ConnectionTypes.AccessCV && {
                eventName: DataPlatformEventName.TSAccessCVCompleted,
              }),
              ...(connectionType === ConnectionTypes.ExportProfileCts && {
                eventName: DataPlatformEventName.TSExportSeekProfileCompleted,
              }),
              metrics: {
                source: Source.Site,
                site,
                resultListTab: TabLocations.Results,
                jobId: jobId ? parseInt(jobId, 10) : null,
                advertiserId,
                userId,
                userSessionId,
                organisationId,
                searchId,
                productType,
                similarCandidatesSearchId,
                ...(atsId && { atsId }),
                ...(atsName && { atsName }),
                ...(externalReferenceId && {
                  externalJobReferenceId: externalReferenceId,
                }),
                ...(decodedExternalReferenceId || {}),
                profile: {
                  ...defaultProfileFields,
                  profilePosition: profilePosition ?? 0,
                  fromProfilePage,
                },
                connectionId,
                tags: tags
                  ? { ...tags, testRecord: tags.testRecord === 'true' }
                  : undefined,
              },
            },
          };
        },
      },
    ];
  },
  cvDownloaded: ({
    profile,
    accessProfileType,
  }: {
    profile: ProfileV2;
    accessProfileType?:
      | ConnectionTypes.AccessProfile
      | ConnectionTypes.AccessProfileAndCv;
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const profileId = String(profile.profileId);
        const seedProfileId =
          routerSelectors.getSeedProfileIdSelector(snapshot);
        const availableConnectionTypes =
          tealiumSelectors.getConnectionTypesAvailableSelector(
            snapshot,
            profile,
          );
        const advertiserType =
          tealiumSelectors.getAdvertiserTypesSelector(snapshot);
        const profileType = profile.profileType;

        const accessCvType = accessProfileType
          ? getAccessCvType(accessProfileType)
          : undefined;

        return {
          action: UtagActions.Link,
          value: {
            eventName: ProfileLinks.CVDownloaded,
            profileId,
            availableConnectionTypes,
            accessCvType,
            advertiserType,
            seedProfileId,
            profileType,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);
        const connectionId = null;

        const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
        const advertiserId = userSelectors.getAdvertiserIdSelector(snapshot);
        const userId = userSelectors.getUserIdSelector(snapshot);
        const userSessionId =
          settingsSelectors.getUserSessionIdSelector(snapshot);
        const organisationId = userSelectors.getCompanyIdSelector(snapshot);
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = profilesSelectors.getProfilePositionSelector(
          snapshot,
          profile,
        );
        const similarCandidatesSearchId =
          searchesSelectors.getSimilarCandidatesSearchIdSelector(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        const productType = userSelectors.getProducTypeForMetrics(
          snapshot,
          true,
        );

        // for Access Profile and Access Profile and Cv types, we do not send to the legacy data platform store.
        // Instead, we send via Hubble
        if (accessProfileType) {
          return {
            value: {},
          };
        }

        return {
          value: {
            eventName: DataPlatformEventName.TSDownloadCvCompleted,
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              advertiserId,
              userId,
              userSessionId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              productType,
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  messageViewed: ({ profile }: { profile: ProfileV2 }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const seedProfileId =
          routerSelectors.getSeedProfileIdSelector(snapshot);
        const availableConnectionTypes =
          tealiumSelectors.getConnectionTypesAvailableSelector(
            snapshot,
            profile,
          );
        const advertiserType =
          tealiumSelectors.getAdvertiserTypesSelector(snapshot);
        const profileType = profile.profileType;

        return {
          action: UtagActions.Link,
          value: {
            eventName: ProfileLinks.SendMessageViewed,
            availableConnectionTypes,
            advertiserType,
            seedProfileId,
            profileType,
          },
        };
      },
    },
  ],
  viewProfileLinkClicked: ({ profile }: { profile: ProfileV2 }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const profileId = String(profile.profileId);
        const seedProfileId =
          routerSelectors.getSeedProfileIdSelector(snapshot);
        const availableConnectionTypes =
          tealiumSelectors.getConnectionTypesAvailableSelector(
            snapshot,
            profile,
          );
        const advertiserType =
          tealiumSelectors.getAdvertiserTypesSelector(snapshot);
        const profileType = profile.profileType;

        return {
          action: UtagActions.Link,
          value: {
            eventName: ProfileLinks.ViewProfileLinkClick,
            profileType,
            profileId,
            availableConnectionTypes,
            advertiserType,
            seedProfileId,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);
        const connectionId = null;

        const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
        const advertiserId = userSelectors.getAdvertiserIdSelector(snapshot);
        const userId = userSelectors.getUserIdSelector(snapshot);
        const userSessionId =
          settingsSelectors.getUserSessionIdSelector(snapshot);
        const organisationId = userSelectors.getCompanyIdSelector(snapshot);
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = profilesSelectors.getProfilePositionSelector(
          snapshot,
          profile,
        );
        const similarCandidatesSearchId =
          searchesSelectors.getSimilarCandidatesSearchIdSelector(snapshot);
        const atsId = userSelectors.getAtsIdSelector(snapshot);
        const atsName = userSelectors.getAtsNameSelector(snapshot);
        const profileType = profile.profileType;
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);

        return {
          value: {
            eventName: DataPlatformEventName.TSAtsCustomAction,
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              advertiserId,
              ...(atsId && { atsId }),
              ...(atsName && { atsName }),
              userId,
              userSessionId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              customActionName: 'View profile',
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
                profileType,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  accessProfileWelcomeModalViewed: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => ({
        action: UtagActions.View,
        value: {
          eventName: ProfileLinks.AccessProfileWelcomeModalViewed,
        },
      }),
    },
  ],
  accessProfileWelcomeModalDismissPressed: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => ({
        action: UtagActions.Link,
        value: {
          eventName: ProfileLinks.AccessProfileWelcomeModalDismissPressed,
        },
      }),
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
