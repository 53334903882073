import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useACL } from '../../hooks/useACL/useACL';
import useDataService from '../../hooks/useDataService';
import * as balanceDataServices from '../../services/data/balance';
import {
  actionCreators as balanceActionCreators,
  selectors as balanceSelectors,
} from '../../store/balance';
import { selectors as userSelectors } from '../../store/user';
import { ACL } from '../../types';
import useJobId from '../useJobId';

import Context, { type ContextType } from './Context';

interface Props {
  children: React.ReactNode;
}

function BalanceProvider({ children }: Props) {
  const dispatch = useDispatch();

  const hasFetchedInitialBalance = useRef<boolean>(false);

  const hasInitialUserData = useSelector(
    userSelectors.getInitialDataStateSelector,
  );
  const balance = useSelector(balanceSelectors.getCreditBalanceSelector);
  const jobId = useJobId();

  const { hasAccess } = useACL();

  const { getBalance } = useDataService({
    getBalance: balanceDataServices.getBalance,
  });

  const fetchAndUpdateBalance: ContextType['fetchAndUpdateBalance'] =
    useCallback(async () => {
      let amount;

      try {
        amount = (await getBalance()).currentBalance;
      } catch (err) {
        amount = 0;
      }

      dispatch(balanceActionCreators.updateUserBalanceToAmount(amount));
      return amount;
    }, [dispatch, getBalance]);

  useEffect(() => {
    (async () => {
      //  We can't fetch any balances until
      //  we know who the user is
      if (!hasInitialUserData) {
        return;
      }
      //  If user is STS we should fetch credits
      //  every time this jobId changes
      if (hasAccess(ACL.CREDITS_FEATURE.CREDITS_FOR_JOB_ONLY)) {
        if (jobId) {
          await fetchAndUpdateBalance();
        }

        //  For all other users, we should fetch
        //  initial balance once when the app loads
      } else if (!hasFetchedInitialBalance.current) {
        hasFetchedInitialBalance.current = true;
        await fetchAndUpdateBalance();
      }
    })();
  }, [fetchAndUpdateBalance, hasAccess, hasInitialUserData, jobId]);

  const context: ContextType = useMemo(
    () => ({
      fetchAndUpdateBalance,
      updateBalance: (amount) => {
        dispatch(balanceActionCreators.updateUserBalanceToAmount(amount));
      },
      deductCredits: (amount) => {
        dispatch(balanceActionCreators.deductCreditsFromBalance(amount));
      },
      balance,
    }),
    [dispatch, fetchAndUpdateBalance, balance],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default BalanceProvider;
