import { ConnectionTypes } from '../../../types';

interface ConnectionFailureParams {
  firstName: string;
  lastName: string;
  connectionType: ConnectionTypes;
  atsName: string;
}

export function createConnectionFailureTemplate(
  translation: (key: string, params?: any) => string,
  { connectionType, firstName, lastName, atsName }: ConnectionFailureParams,
) {
  switch (connectionType) {
    case ConnectionTypes.SendJob:
      return {
        message: translation('SEND_JOB'),
        description: translation('SEND_JOB_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case ConnectionTypes.SendMessage:
      return {
        message: translation('SEND_MESSAGE'),
        description: translation('SEND_MESSAGE_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case ConnectionTypes.AccessCV:
      return {
        message: translation('ACCESS_CV'),
        description: translation('ACCESS_CV_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case ConnectionTypes.AccessProfile:
      return {
        message: translation('ACCESS_PROFILE'),
        description: translation('ACCESS_PROFILE_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case ConnectionTypes.AccessProfileAndCv:
      return {
        message: translation('ACCESS_PROFILE_AND_CV'),
        description: translation('ACCESS_PROFILE_AND_CV_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case ConnectionTypes.ExportProfileCts:
      return {
        message: translation('ADD_PROFILE'),
        description: translation('ADD_PROFILE_TO_ATS', {
          firstName,
          lastName,
          atsName,
        }),
      };

    default:
      return {
        message: translation('CONNECT'),
        description: translation('CONNECT_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };
  }
}

export function createConnectionSuccessTemplate(
  translation: (key: string) => string,
  connectionType: ConnectionTypes,
) {
  switch (connectionType) {
    case ConnectionTypes.SendJob:
      return {
        message: translation('JOB_SENT'),
      };

    case ConnectionTypes.SendMessage:
      return {
        message: translation('MESSAGE_SENT'),
      };

    case ConnectionTypes.AccessCV:
      return {
        message: translation('CV_ACCESSED'),
      };

    case ConnectionTypes.AccessProfile:
      return {
        message: translation('PROFILE_ACCESSED'),
      };

    case ConnectionTypes.AccessProfileAndCv:
      return {
        message: translation('PROFILE_AND_CV_ACCESSED'),
      };

    case ConnectionTypes.ExportProfileCts:
      return {
        message: translation('PROFILE_ADDED'),
      };

    default:
      return {
        message: translation('CONNECTION_SUCCESSFUL'),
      };
  }
}

export function createThrottlingLimitTemplate(
  translation: (key: string, params?: any) => string,
  {
    connectionType,
  }: {
    connectionType: ConnectionTypes;
  },
) {
  switch (connectionType) {
    case ConnectionTypes.SendJob:
      return { message: translation('SEND_JOB') };

    case ConnectionTypes.SendMessage:
      return { message: translation('SEND_MESSAGE') };

    case ConnectionTypes.AccessCV:
      return { message: translation('ACCESS_CV') };

    case ConnectionTypes.AccessProfile:
      return { message: translation('ACCESS_PROFILE') };

    case ConnectionTypes.AccessProfileAndCv:
      return { message: translation('ACCESS_PROFILE') };

    case ConnectionTypes.ExportProfileCts:
      return { message: translation('ADD_PROFILE') };

    default:
      return { message: translation('CONNECTION') };
  }
}
