import type {
  AsyncState,
  AsyncProfileState,
  AssignedPools,
  LatestInteraction,
} from '../../../types';

const getNextEntries = ({
  currentEntries,
  assignedPoolsForProfiles,
  latestInteractionsForProfiles,
}: {
  currentEntries: AsyncState['entries'];
  assignedPoolsForProfiles: AssignedPools[];
  latestInteractionsForProfiles: LatestInteraction[];
}) => {
  const nextEntries: Record<string, AsyncProfileState> = {};

  for (const [profileId, profile] of Object.entries(currentEntries)) {
    const { assignedPools } = assignedPoolsForProfiles.find(
      (assignedPool) => profileId === assignedPool.profileId,
    ) || { assignedPools: [] };

    const latestInteraction =
      latestInteractionsForProfiles.find(
        (interaction) => profileId === String(interaction.profileId),
      ) || null;

    nextEntries[profileId] = {
      ...profile,
      assignedPools,
      latestInteraction,
      hasConnectionHistory: latestInteraction
        ? latestInteraction.count > 0
        : false,
    };
  }
  return nextEntries;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getNextEntries;
