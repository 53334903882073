import type { ProfileV2 } from 'src/types';

export const getSearchResultTrackingFields = (
  profiles: ProfileV2[],
): {
  searchResultsPageSize: number;
  searchResultsVerifiedCredentialsCount: number;
  searchResultsVerifiedIDCount: number;
  searchResultsVerifiedRTWCount: number;
  searchResultsVerifiedLicenceCount: number;
  searchResultsVerifiedEducationCount: number;
  searchResultsNationalityCount: number;
} => {
  const searchResultsPageSize = profiles.length;
  const searchResultsVerifiedCredentialsCount: number = profiles.filter(
    (profile) => profile.hasVerifiedCredentials,
  ).length;

  // @TODO: Hook this up once it's available
  const searchResultsVerifiedIDCount = 0;
  // eg. maybe like this? Depends on the shape of the data once the API is
  // all hooked up
  // profiles.filter((profile) => profile.hasVerifiedId).length;

  const searchResultsVerifiedRTWCount: number = profiles.filter(
    (profile) => profile.rightToWork?.isVerified,
  ).length;

  const searchResultsVerifiedLicenceCount: number = profiles.filter(
    (profile) => {
      const verifiedLicencesCount =
        profile.licences?.filter((licence) => licence.isVerified).length ?? 0;
      return verifiedLicencesCount > 0;
    },
  ).length;

  const searchResultsVerifiedEducationCount: number = profiles.filter(
    (profile) => {
      const verifiedEducationCount =
        profile.profileEducation?.filter((education) => education.isVerified)
          .length ?? 0;
      return verifiedEducationCount > 0;
    },
  ).length;

  const searchResultsNationalityCount: number = profiles.filter(
    (profile) => profile.nationalities.length > 0,
  ).length;

  return {
    searchResultsPageSize,
    searchResultsVerifiedCredentialsCount,
    searchResultsVerifiedIDCount,
    searchResultsVerifiedRTWCount,
    searchResultsVerifiedLicenceCount,
    searchResultsVerifiedEducationCount,
    searchResultsNationalityCount,
    // Please add new tealium tracking fields here
  };
};
