import { UivBanner } from '@seek/uiv-banner';
import { useTranslations } from '@vocab/react';
import {
  Alert,
  Box,
  ButtonLink,
  Inline,
  Strong,
  Text,
} from 'braid-design-system';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import useAppConfig from 'src/hooks/useAppConfig';

import { useACL } from '../../../../hooks/useACL/useACL';
import useImpersonation from '../../../../hooks/useImpersonation';
import useNotifications from '../../../../hooks/useNotifications';
import queryStringService from '../../../../services/location/queryStringService';
import { selectors as settingsSelectors } from '../../../../store/settings';
import { selectors as userSelectors } from '../../../../store/user';
import { ACL } from '../../../../types';

import translations from './.vocab';
import { MessageDisplay } from './components';

import * as styles from './StickyBanners.css';

const getActorDisplayText = (email: string): string => {
  let displayText = 'Operator';
  if (email && email.indexOf('@') > -1) {
    displayText = email.substring(0, email.indexOf('@'));
  }
  return displayText;
};

const WIDGET_BACKOFFICE_URL =
  'https://jobmarley-talentsearch.ssod.skinfra.xyz/impersonate';

function StickyBanners() {
  const { t } = useTranslations(translations);
  const { APP_NAME, APP_VERSION, BRANCH_NAME } = useAppConfig();
  const { getActiveBanner } = useNotifications();
  const { isImpersonating, getDetails } = useImpersonation();

  const { getProductType } = useACL();

  const settingOverrides = useSelector(
    settingsSelectors.getSettingsOverrideSelector,
  );
  const advertiserName = useSelector(userSelectors.getAdvertiserNameSelector);
  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);
  const atsId = useSelector(userSelectors.getAtsIdSelector);

  const activeBanner = getActiveBanner();
  const impersonationDetails = isImpersonating ? getDetails() : null;

  //  One of these should render the sticky container
  //  otherwise we'll get no banners and that is no fun
  //  No need to translate branch-banner, overrides-banner as they are only used for debugging
  const shouldRenderStickyContainer =
    impersonationDetails || activeBanner || settingOverrides.length > 0;

  // Logic to show UIV Banner is inside the UIVBanner component.
  const renderUIVBanner = () => (
    <UivBanner
      applicationName={APP_NAME}
      branch={BRANCH_NAME}
      version={APP_VERSION}
    />
  );

  if (!shouldRenderStickyContainer) {
    return (
      <Box className={styles.container} zIndex="sticky">
        {renderUIVBanner()}
      </Box>
    );
  }

  return (
    <Box className={styles.container} zIndex="sticky">
      {renderUIVBanner()}
      {settingOverrides.length > 0 && (
        <Box
          id="overrides-banner"
          padding="small"
          background="promote"
          textAlign="center"
        >
          <Text size="small">
            <Strong>Overrides:</Strong>{' '}
            {settingOverrides.map(({ key, value }) => (
              <Fragment key={key}>{`[${key} ${value}]`}</Fragment>
            ))}
          </Text>
        </Box>
      )}

      {impersonationDetails && (
        <Box
          id="impersonation-banner"
          padding="small"
          background="brandAccent"
          textAlign="center"
        >
          <Inline alignY="center" space="small" align="center">
            <Text size="small">
              {t('ACTOR_EMAIL_IS_IMPERSONATING_ACT_AS_EMAIL', {
                ActorEmail: () => (
                  <Strong>
                    {getActorDisplayText(impersonationDetails.actorEmail)}
                  </Strong>
                ),
                ActAsEmail: () => (
                  <Strong>
                    {impersonationDetails.actAsEmail} ({advertiserName}{' '}
                    {advertiserId})
                  </Strong>
                ),
              })}
            </Text>

            {getProductType() === ACL.PRODUCT_TYPE.CTS && (
              <ButtonLink
                href={`${WIDGET_BACKOFFICE_URL}?${queryStringService.stringify({
                  atsId,
                  advertiserId,
                  auth0Id: impersonationDetails.actAsId,
                })}`}
                target="_blank"
                variant="ghost"
                size="small"
              >
                {t('IMPERSONATE_TSC')}
              </ButtonLink>
            )}
          </Inline>
        </Box>
      )}

      {activeBanner && (
        <Box id="notifications-banner">
          <Alert
            id={activeBanner.key}
            tone={activeBanner.tone}
            onClose={() => activeBanner.onDismiss(activeBanner.key)}
            closeLabel={t('DISMISS')}
          >
            <MessageDisplay banner={activeBanner} />
          </Alert>
        </Box>
      )}
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default StickyBanners;
