export enum Data {
  AddToPool = 'add-to-pool',
  Connections = 'profile-card-connections',
  Name = 'profile-card-name',
  PoolsList = 'pooling-pool-list',
  MenuListOptions = 'menu-list-options',
  ModalContent = 'modal-content',
  ProfileCard = 'profile-card',
  PoolingOptions = 'pooling-options',
  ProfileDrawer = 'profile-drawer',
  LastInteractionLink = 'last-interaction-link',
  SimilarCandidatesList = 'similar-candidates-list',
  SimilarCandidateSeeAll = 'similar-candidates-see-all',
  ErrorAlert = 'error-alert',
  Logo = 'logo',
  AppReadyIdentifier = 'app-ready-identifier',
  AhpraRegistration = 'profile-card-credentials-ahpra',
  Licences = 'profile-licences',
  RecentSearch = 'recent-search',
  ActiveFilters = 'active-filters',
  ActiveFilterPill = 'active-filter-pill',
  SearchButton = 'search-button',
  SavedSearchCard = 'saved-search-card',
  SavedSearchName = 'saved-search-name',
  FilterItem = 'filter-item',
  FilterPanel = 'filter-panel',
  SortBy = 'sort-by',
  SaveOrSavedSearch = 'save-or-saved-search',
  SubmitButton = 'submit-button',
  SimilarCandidateCard = 'similar-candidate-card',
  CreditsBalance = 'balance',
  Account = 'account',
  AccountToggle = 'account-toggle',
  LocationMarketSelector = 'location-market-selector',
  SelectedLocationTag = 'location-tag',
}

export enum LocalStorageKey {
  // DEPRECATED: due to th introduction of the gRTW, we will use the new key
  RecentSearches = 'recentSearchesInfo',
  RecentSearchesInfoV2 = 'recentSearchesInfoV2',
  EthicalHiring = 'ethicalHiring',
  AccessProfileEmail = 'accessProfileEmail',
  OneTimePopovers = 'oneTimePopovers',
  SendMessageHirerDetails = 'sendMessageHirerDetails',
  LastViewedProfileId = 'lastViewedProfileId',
  LastKnownAccessMethod = 'lastKnownAccessMethod',
  DirectAccessMethod = 'direct',
  UserSessionId = 'userSessionId',
  FeatureHighlights = 'featureHighlights',
  RedirectUri = 'redirectUri',
}
