import { useHubble } from '@seek/hubble';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defaultEnvironment } from 'src/config/getEnvironment';
import useAppConfig from 'src/hooks/useAppConfig';
import { getCreditRates } from 'src/services/creditRates/getCreditRates';
import { overrideSettings } from 'src/store/settings/reducer';

import { useACL } from '../../../../../hooks/useACL/useACL';
import useDataService from '../../../../../hooks/useDataService';
import useDatadogRum from '../../../../../hooks/useDatadogRum';
import useJobId from '../../../../../hooks/useJobId';
import * as featuresDataServices from '../../../../../services/data/features';
import * as userDataServices from '../../../../../services/data/user';
import { selectors as settingsSelectors } from '../../../../../store/settings';
import {
  actionCreators as userActionCreators,
  selectors as userSelectors,
} from '../../../../../store/user';
import { AccessMethods, Environment, type User } from '../../../../../types';
import RouteLoader from '../components/RouteLoader';

function withUserContext<P>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> {
  return (props: P) => {
    const dispatch = useDispatch();
    const ACL = useACL();
    const datadogRum = useDatadogRum();
    const jobId = useJobId();
    const advertiserId = useSelector(
      settingsSelectors.getPreferredAdvertiserId,
    );

    const { BRANCH_NAME: branchName, ENVIRONMENT: environment } =
      useAppConfig();

    const hubble = useHubble();
    //  Initial user context (user | advertiser | ats)
    const hasUserContext = useSelector(userSelectors.getHasUserContextSelector);

    //  All required user context fields (feature flags | rates)
    const hasAllRequiredUserContext = useSelector(
      userSelectors.getHasAllRequiredUserContextSelector,
    );

    const [isLoading, setIsLoading] = useState(!hasAllRequiredUserContext);

    const userRef = useRef<User | null>(null);

    const { getUserContext, getUserFeatureFlags } = useDataService({
      getUserContext: userDataServices.getUserContext,
      getUserFeatureFlags: featuresDataServices.getUserFeatureFlags,
    });

    const settingOverrides = useSelector(
      settingsSelectors.getSettingsOverrideSelector,
    );

    const isBetaEnvironment = settingOverrides
      ? settingOverrides.some(
          (setting) => setting.key === overrideSettings.BETA_ENVIRONMENT,
        )
      : false;

    /*
     * Resolves initial user context and updates
     * global state with context
     */
    useEffect(() => {
      (async () => {
        if (hasUserContext) {
          return;
        }

        const _userContext = await getUserContext({
          ...(advertiserId && { advertiserId }),
          ...(jobId && { jobId }),
        });

        if (!_userContext) {
          return;
        }

        const { user, advertiser, ats } = (userRef.current = _userContext);
        const { tags } = advertiser;

        hubble.updateOptions({
          ...(user.id && { loginId: user.id.toString() }),
          ...(tags && {
            tags: {
              ...(tags.testRecord === 'true' && {
                testRecord: 'true',
              }),
              ...(tags.testScope && { testScope: tags.testScope }),
              ...(tags.recordExpiry && { recordExpiry: tags.recordExpiry }),
              ...(tags.testBehaviours && {
                testBehaviours: tags.testBehaviours,
              }),
            },
          }),
          // Set isProduction to true only if it is the master branch, the production environment, and there is no beta environment enabled (e.g., LaunchDarkly).
          // When isProduction is false, isDevelopment = true is sent to Hubble, routing data to a separate dev dataset to avoid interfering with production analysis.
          isProduction:
            branchName === defaultEnvironment.BUILDKITE_BRANCH &&
            environment === Environment.Production &&
            !isBetaEnvironment,
        });

        //  Re-fetches authentication status from TS-API so that we can
        //  set both the authenticated status and access method into global state
        dispatch(
          userActionCreators.setIsAuthenticated({
            isAuthenticated: true,
            accessMethod: AccessMethods.Direct,
          }),
        );

        //  Sets user context and updates the `hasUserContext` flag to TRUE
        dispatch(
          userActionCreators.setUserContext({
            user,
            advertiser,
            ...(ats && { ats }),
          }),
        );
      })();
    }, [
      getUserContext,
      dispatch,
      hasUserContext,
      advertiserId,
      jobId,
      hubble,
      branchName,
      environment,
      isBetaEnvironment,
    ]);

    /*
     * Resolves extended user context (flags, credit rates)
     * And configures access control for the application
     */
    useEffect(() => {
      (async () => {
        if (!hasUserContext || !userRef.current) {
          return;
        }

        const { advertiser } = userRef.current;

        const featureFlags = await getUserFeatureFlags();
        //  Configure ACL for user
        ACL.setUser(userRef.current, featureFlags);

        const productType = ACL.getProductType();

        //  Set RUM context
        datadogRum.setUser({
          id: String(advertiser.id),
          name: advertiser.name,
          plan: productType,
        });

        //  Sets user context and updates the `hasExtendedUserContext` flag to TRUE
        dispatch(
          userActionCreators.setExtendedUserContext({
            featureFlags,
            rates: getCreditRates(productType),
          }),
        );

        setIsLoading(false);
      })();
    }, [getUserFeatureFlags, hasUserContext, ACL, dispatch, datadogRum]);

    if (isLoading) {
      return <RouteLoader />;
    }
    return <WrappedComponent {...(props as any)} />;
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default withUserContext;
