import { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from '../../store/settings';
import { selectors as userSelectors } from '../../store/user';
import { ACL, Environment } from '../../types';
import { useACL } from '../useACL/useACL';
import useAppConfig from '../useAppConfig';
import useAuth from '../useAuth';
import useJobId from '../useJobId';

import getFirstActiveJobId from './getFirstActiveJobId';
import type { CustomHeaders } from './types';
import useResolveOnceAndCache from './useResolveOnceAndCache';

type PendingRequest = (value: CustomHeaders) => void;

function useCustomHeaders() {
  const { getProductType } = useACL();
  const { getToken } = useAuth();
  const { HIRER_GRAPH_URL, ENVIRONMENT } = useAppConfig();

  const hasUserContext = useSelector(userSelectors.getHasUserContextSelector);
  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);
  const userId = useSelector(userSelectors.getUserIdSelector);
  const activeJobId = useJobId();
  const betaEnvironment = useSelector(
    settingsSelectors.getBetaEnvironmentSelector,
  );

  const jobIdRef = useRef<number | null>(activeJobId);

  const [pendingRequests, setPendingRequests] = useState<PendingRequest[]>([]);

  //  Ensures this function will resolve once and return
  //  the cached results on all subsequent requests
  const _getFirstActiveJobId = useResolveOnceAndCache<string | null>(
    getFirstActiveJobId,
  );

  const getAdvertiserIdHeader = useCallback(
    () =>
      Promise.resolve({
        ...(advertiserId && { advertiserId: String(advertiserId) }),
      }),
    [advertiserId],
  );

  const getUserIdHeader = useCallback(
    () =>
      Promise.resolve({
        ...(userId && { userId: String(userId) }),
      }),
    [userId],
  );

  const getJobIdHeader = useCallback(
    async () => ({
      ...(activeJobId && { jobId: String(activeJobId) }),
    }),
    [activeJobId],
  );

  //  Fetches a jobId if one has not previously been set
  const getOrResolveJobIdHeader = useCallback(async () => {
    let jobId = jobIdRef.current || null;
    if (!jobId) {
      const token = await getToken();
      jobId = Number(
        await _getFirstActiveJobId({
          hirerGraphUrl: HIRER_GRAPH_URL,
          advertiserId,
          token,
        }),
      );
      jobIdRef.current = jobId;
    }
    return {
      ...(jobId && { jobId: String(jobId) }),
    };
  }, [getToken, _getFirstActiveJobId, HIRER_GRAPH_URL, advertiserId]);

  const getBetaEnvironmentHeader = useCallback(
    () => ({
      ...(betaEnvironment && { environment: betaEnvironment }),
    }),
    [betaEnvironment],
  );

  //  Configures requirements on a per-product basis
  //  to ensure we are sending required headers
  const getHeaderConfig = useCallback(
    (productType: ACL.ProductType | null) => {
      const defaultHeaders =
        ENVIRONMENT === Environment.Production
          ? [getBetaEnvironmentHeader, getAdvertiserIdHeader]
          : [getBetaEnvironmentHeader, getAdvertiserIdHeader, getUserIdHeader];

      switch (productType) {
        case ACL.PRODUCT_TYPE.STS:
          return [...defaultHeaders, getOrResolveJobIdHeader];
        default:
          return [...defaultHeaders, getJobIdHeader];
      }
    },
    [
      ENVIRONMENT,
      getBetaEnvironmentHeader,
      getAdvertiserIdHeader,
      getJobIdHeader,
      getOrResolveJobIdHeader,
      getUserIdHeader,
    ],
  );

  const resolveHeaders = useCallback<() => Promise<CustomHeaders>>(
    () =>
      getHeaderConfig(getProductType()).reduce(
        async (acc, resolver) => ({
          ...(await acc),
          ...(await resolver()),
        }),
        Promise.resolve({}),
      ),
    [getHeaderConfig, getProductType],
  );

  //  Keep a copy of /:jobId
  //  from the URL when one can be found
  useEffect(() => {
    if (activeJobId) {
      jobIdRef.current = activeJobId;
    }
  }, [activeJobId]);

  useEffect(() => {
    (async () => {
      if (!hasUserContext) {
        return;
      }
      for (const resolve of pendingRequests) {
        resolve(await resolveHeaders());
      }
    })();
  }, [hasUserContext, pendingRequests, resolveHeaders]);

  return (prevContext?: {
    disableAuthorisationHeaders?: boolean;
  }): Promise<CustomHeaders> =>
    new Promise(async (resolve) => {
      if (prevContext?.disableAuthorisationHeaders) {
        return resolve({});
      }
      if (hasUserContext) {
        return resolve(await resolveHeaders());
      }
      setPendingRequests((prevState) => [...prevState, resolve]);
    });
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useCustomHeaders;
